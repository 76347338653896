import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import NewsStrip from "../components/news-strip"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Privacy policy" description="We take your privacy concerns seriously at Tetley's Coaches. Read our privacy policy." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Privacy Policy</h1>
            </div>

            <div className="news-article-body">

            <p>This privacy policy explains how we use any personal information we collect about you.</p>

            <h2>What information do we collect about you?</h2>

            <p>We collect information about you when you make a booking. We also collect information when you voluntarily
            complete customer surveys, provide feedback and participate in competitions. No website information is
            collected using cookies.</p>

            <h2>How will we use the information about you?</h2>

            <p>We collect information about you to process your booking and manage your account. We shall not share your
            personal information with companies outside Tetley's Motor Services Limited. In processing your order, we may
            send your details to, and use information from credit reference agencies and fraud prevention agencies.</p>

            <h2>Marketing</h2>

            <p>We will not send you information about services of ours unless you specifically request that information.</p>

            <h2>Access to your information and correction</h2>

            <p>You have the right to request a copy of the information that we hold about you. If you would like a copy of some
            or all your personal information, please email <a href="mailto:sales@tetleyscoaches.co.uk">sales@tetleyscoaches.co.uk</a> or write to us at Tetley's Motor
            Services Limited, 76 Goodman Street, Leeds LS10 1NY.</p>

            <p>We want to make sure that your personal information is accurate and up to date. You may ask us to correct or
            remove information you think is inaccurate.</p>

            <h2>Cookies</h2>

            <p>We use cookies to analyse basic but important data to improve our services that we provide to you.</p>

            <table id="cookie-table">
              <tr>
                <th className="cookietableheader">Cookie Type</th>
                <th className="cookietableheader">How they're used</th>
              </tr>
              <tr>
                <td className="cookietype">_ga, _gat_UA-XXXXXXX-XX, _gid</td>
                <td>We use these performance cookies to anonymously collect and analyse visitors data via Google Tag Manager and Google Analytics.</td>
              </tr>
              <tr>
                <td className="cookietype">MUID, MUIDB, ANON and NAP</td>
                <td>These cookies are used by Bing to help identify user sessions to enhance our marketing offering.</td>
              </tr>
            </table>

            <h2>Other websites</h2>

            <p>Our website contains links to other websites. This privacy policy only applies to this website so when you link to
            other websites you should read their own privacy policies.</p>

            <h2>Changes to our privacy policy</h2>

            <p>We keep our privacy policy under regular review and we will place any updates on our website. This privacy
            policy was last updated on 20 August 2018.</p>

            <h2>How to contact us</h2>

            <p>Please contact us if you have any questions about this policy or information we hold about you, by email at
             <a href="mailto:sales@tetleyscoaches.co.uk">sales@tetleyscoaches.co.uk</a> or in writing to Tetley's Motor Services Limited, 76 Goodman Street, Leeds LS10
            1NY.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
